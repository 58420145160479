
import {localize, configure} from 'vee-validate'
import I18n from '@plugins/i18n'
import {default as elUiLocale} from 'element-ui/lib/locale';
import {showSweetMessage} from '@resources/js/helpers/utils/partial'


const state = {

    is_multilang_project: process.env.MIX_IS_MULTILANG_PROGECT == 1,

    settingsLoaded: false,

    APP_INTERFACE: '',
    APP_ENV: process.env.MIX_ENV,

    // isRTL: false,
    windowWidth: 0,
    currentBreakPoint: '',
    shallShowOverlay: false,
    isSearchWindowOpened: false,
    pageTemplateClass: '',
    currentRoute: {},

    appName: localStorage.getItem('app_name'),
    appLogo: localStorage.getItem('app_logo'),
    appLocale: localStorage.getItem('app_locale'),

    base_catalog: '',
    appCurrency: '',

    translations: [],
    perPageOptions: [10, 25, 50, 100],
    perPage: 25,
    locales: [],

    bonuses_system_enabled: 0,
    notificationsCount: 0,
    genders: [{label: I18n.t('app.man'), value: 1}, {label: I18n.t('app.woman'), value: 2}],
    disableSiteReason: '',

    away_redirect_sweet_error: '',
    away_redirect_sweet_success: '',

    countries: [],

    menuCategories: [],

    stripe_key: '',

    pages: [],
    pageLinkLocations: ['footer', 'top_menu', 'without_location'],
    main_page_slides: [],
    home_banner_data: {},

    orderStatuses: [
        'new', 'in_progress', 'rejected', 'taken', 'is_send', 'returned'
    ],
    paymentStatuses: [
            'is_paid', 'not_paid'
    ],

    payment_types: [],

    coupon_functionality: 0,

    socials: [],

    contact_email: '',
    contact_phone: '',
    contact_address: '',


};

const mutations = {

    UPDATE_WINDOW_WIDTH(state, windowWidth) {
        state.windowWidth = windowWidth;

    },
    UPDATE_CURRENT_BREAK_POINT(state, windowWidth) { //get from store sort by width
        let currentBreakPoint = 'xs';
        if (windowWidth >= this.state['theme'].themeBreakpoints.xl){
            currentBreakPoint = 'xl';
        } else if (windowWidth >= this.state['theme'].themeBreakpoints.lg){
            currentBreakPoint =  'lg';
        } else if (windowWidth >= this.state['theme'].themeBreakpoints.md){
            currentBreakPoint =  'md';
        } else if (windowWidth >= this.state['theme'].themeBreakpoints.show_mobile_menu){
            currentBreakPoint =  'show_mobile_menu';
        } else if (windowWidth >= this.state['theme'].themeBreakpoints.sm){
            currentBreakPoint =  'sm';
        }
       state.currentBreakPoint = currentBreakPoint;
       // console.log('fff',state.windowWidth,currentBreakPoint);

    },

    TOGGLE_IS_SEARCH_WINDOW_OPENED(state, val) {
        val = val !== undefined ? val : !state.isSearchWindowOpened;
        window.document.documentElement.style.overflow = val? 'hidden' : 'auto';
        state.isSearchWindowOpened = val;
    },
    //
    // TOGGLE_OVERLAY(state, val) {
    //     state.shallShowOverlay = val !== undefined ? val : !state.shallShowOverlay
    // },

    UPDATE_CURRENT_ROUTE(state, val) {
        state.currentRoute = val
        state.pageTemplateClass = val.meta.pageTemplateClass != undefined? val.meta.pageTemplateClass : '';
    },

    changeLocale(state, {locale, translations, rootState}) {
    // console.log('changeLocale',locale,translations);
        localStorage.setItem('app_locale',locale);

        state.translations[locale] = translations;

        I18n.setLocaleMessage(locale, translations); //text
        I18n.locale = locale;

        //vee-validate localization
        configure({
            generateMessage: localize({
                [locale]: {
                    "code": locale,
                    "messages": state.translations[locale]['validation_front']
                }
            })
        });
        // console.log('change locale:',state, state.translations[locale]['validation_front'], locale);
        localize(locale); //vee-validate trans
        //end vee-validate localization

        const elementlang = require('@plugins/element-ui/locales/'+locale+'.json');
        elUiLocale.use(elementlang); // element-ui

        this._vm.$moment.locale(locale); //moment

        state.appLocale = locale;
    },

    SET_ACTIVE_APP_INTERFACE(state, val){
        state.APP_INTERFACE = val;
    },

    setSiteSettings(state, data){

        let hash = (new Date).getTime();

        state.locales = data.locales;
        // console.log('setSiteSettings',data.locales,state.locales,state.appLocale);
        state.appLocale = data.base.current_locale;
        state.appName = data.base.app_name;
        // localStorage.setItem('app_name', data.base.app_name);
        state.appCurrency = data.base.currency;
        state.bonuses_system_enabled = data.bonuses_system_enabled;
        state.APP_ENV = process.env.MIX_ENV;
        state.appLogo = data.base.logo + '?hash=' + hash;
        // state.base_catalog = data.base.base_catalog;
        state.stripe_key = data.sk;
        state.countries = data.countries;
        state.pages = data.pages;
        state.pageLinkLocations = data.pageLinkLocations? data.pageLinkLocations : state.pageLinkLocations;
        state.main_page_slides = data.main_page_slides;
        state.home_banner_data = data.home_banner_data;
        state.socials = data.socials;

        state.contact_phone = data.contact_phone;
        state.contact_email = data.contact_email;
        state.contact_address = data.contact_address;

        state.payment_types = data.payment_types;

        state.away_redirect_sweet_error = data.away_redirect_sweet_error,
        state.away_redirect_sweet_success = data.away_redirect_sweet_success,


        state.menuCategories = data.menu_categories;

        if(data.base.favicon) {
            document.getElementById('favicon-icon').href = this._vm.$API_DOMAIN + data.base.favicon + '?hash=' + hash;
        }

        state.settingsLoaded = true;
    },

    setDisableSiteReason(state, val){
        state.disableSiteReason = val;
    },

    setNotificationsCount(state, val){
        state.notificationsCount = val;
    },

    disableSideMenu(state){
        state.side_menu = false;
    }
};

const getters = {
    // currentBreakPoint(state, tt, rootState){
    //     // console.log('cur  break', state);
    //    return state.currentBreakPoint;
    // },
    getAppLogo(state){
        return state.appLogo? state.appLogo : localStorage.getItem('app_logo');
    },
    getAppName(state){
        return state.appName? state.appName : localStorage.getItem('app_name');
    },
    // getAppBaseCatalog(state){
    //     return state.base_catalog? state.base_catalog : localStorage.getItem('app_base_catalog');
    // }
};

const actions = {

    UPDATE_LOCALE({dispatch, state, commit, rootState}, data) {

            let vm = window.$_app;

                if(data.set && vm.$user.auth){ //save chosen locale to logged in user
                    // alert(vm.$user.auth);
                    return new Promise((resolve, reject) => {
                        $http.async(vm, 'get','api/setLocale', {locale:data.locale}, function(resp){
                        localStorage.setItem('app_locale',data.locale);
                        vm.reloadRootComponent();
                        resolve(resp);
                    });
                });
                } else { // just reload content with lang settings
                    localStorage.setItem('app_locale',data.locale);
                    vm.reloadRootComponent();
                }

    },

    GET_SITE_SETTINGS({dispatch, state, commit, rootState}, data) {

        return new Promise((resolve, reject) => {

            return $http.publicAsync('get','api/settings', {items: ['site_settings'] }, function (response) {

                        let vm = window.$_app;

                        document.documentElement.lang = response.data.base.current_locale || 'en'
                        document.documentElement.inited = true

                        let title = vm.$router.currentRoute.meta.title?  vm.$t(vm.$router.currentRoute.meta.title) : '';
                        document.title = title || response.data.base.app_name;
                        if(vm.$store.state['app'].away_redirect_sweet_error){
                            showSweetMessage(vm.$store.state['app'].away_redirect_sweet_error, 'error');
                        } else if(vm.$store.state['app'].away_redirect_sweet_success){
                            showSweetMessage(vm.$store.state['app'].away_redirect_sweet_success, 'success');
                        }

                        commit('setSiteSettings',  response.data);

                        commit('changeLocale', {
                            locale: response.data.base.current_locale,
                            translations: response.data.translations,
                            rootState: rootState
                        });

                        return resolve(response);

                    }, function(error){
                // console.log('error');
                reject(error);
            }, {
                cache: true,
                cache_force_update: data.cache_force_update,
                cache_expire_timeout: 1000 * 60 * 60 //60min
            });
                    // .catch(error => reject(error));
        });

    },

    GET_FLASHMESSAGE({dispatch, state, commit, rootState}, data) {

            return $http.async(window.$_app,'get','api/flashmessage', { }, function (response) {

                        // return resolve(response);

                    }, function(error){
                // console.log('error');
                // reject(error);
            }, {});
                    // .catch(error => reject(error));

    },

    // acceptConfirm({commit}){
    //     return new Promise((resolve, reject) => {
    //         setTimeout(() => {
    //             resolve({
    //                 items: [1, 2],
    //                 total: 12
    //             });
    //         }, 2000);
    //     });
    // },


};

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
}

