import Vue from 'vue'

import {ToastPlugin, ModalPlugin, BButton, BRow, BCol, BBreadcrumb, BBreadcrumbItem, BSpinner, VBTooltip} from 'bootstrap-vue'

Vue.use(ToastPlugin);
Vue.use(ModalPlugin);

Vue.component('b-row', BRow);
Vue.component('b-col', BCol);
Vue.component('b-spinner', BSpinner);
Vue.component('b-button', BButton);
Vue.component('b-breadcrumb', BBreadcrumb);
Vue.component('b-breadcrumb-item', BBreadcrumbItem);
Vue.directive('b-tooltip', VBTooltip);


