import moment from 'moment'
import i18n from "@plugins/i18n";

import { extend, localize } from 'vee-validate'
import {
  required as rule_required,
  required_if as rule_required_if,
  email as rule_email,
  min as rule_min,
  max as rule_max,
  confirmed as rule_confirmed,
  regex as rule_regex,
  between as rule_between,
  alpha as rule_alpha,
  integer as rule_integer,
  digits as rule_digits,
  alpha_dash as rule_alpha_dash,
  alpha_num as rule_alpha_num,
  length as rule_length,
  min_value as rule_min_value,
  max_value as rule_max_value,
} from 'vee-validate/dist/rules'

import {
    validatorPositive,
    validatorUrlValidator,
    validatorPassword,
    validatorCreditCard,
    validatorMyRule,
    validatorRequiredAtLeastOne,
    validatorNumber,
    validatorDateGtCurrent,
    validatorDateGtThan
} from './validators'

// ////////////////////////////////////////////////////////
// General
// ////////////////////////////////////////////////////////
export const email = extend('email',rule_email);

export const required = extend('required', rule_required)
export const required_if = extend('required_if', rule_required_if)



export const min = extend('min', rule_min)
export const max = extend('max', rule_max)

export const confirmed = extend('confirmed', rule_confirmed)

export const regex = extend('regex', rule_regex)

export const between = extend('between', rule_between)

export const alpha = extend('alpha', rule_alpha)

export const integer = extend('integer', rule_integer)

export const digits = extend('digits', rule_digits)

export const alphaDash = extend('alpha-dash', rule_alpha_dash)

export const alphaNum = extend('alpha-num', rule_alpha_num)
export const min_value = extend('min_value', rule_min_value)
export const max_value = extend('max_value', rule_max_value)

export const length = extend('length', rule_length)

export const positive = extend('positive', {
  validate: validatorPositive,
  message: 'Please enter positive number!',
})

export const credit = extend('credit-card', {
  validate: validatorCreditCard,
  message: 'It is not valid credit card!',
})

export const requiredAtLeastOne = extend('required-at-least-one', {

    validate: validatorRequiredAtLeastOne,

})


export const password = extend('password', {
  validate: validatorPassword,
  message: 'Your {_field_} must contain at least one uppercase, one lowercase, one special character and one digit',
})

export const url = extend('url', {
  validate: validatorUrlValidator,
  message: 'URL is invalid',
})


export const numeric = extend('numeric', {
    validate: validatorNumber,
})

export const dateGtCurrent = extend('date-gt-current', {
    validate(val,params) {
        let format = params[0];

        let currentData = moment().hours(0).minutes(0).seconds(0).unix();
        let featureData = moment(val, format).unix();

        return featureData >= currentData;
    }
})

export const requiredIfEmpty = extend('required-if-empty', {
    params: ['anotherField'],
    validate(value, { anotherField }) {
        // console.log(value, anotherField, 'res:', (value && value.length > 0)  || (anotherField &&  anotherField.length > 0));
        return  (value && value.length > 0)  || (anotherField &&  anotherField.length > 0);
    },
     // message: 'eerer'
});
export const priceField = extend('price', {
    validate(val) {
        if (val === undefined || val === null ) {
            return true
        }
        const reg = /^[0-9\s]*([,\.]\d+)?$/;

        return reg.test(val);
    },
    // message: 'This field value must be between {min} and {max}'
});

export const checkboxIsRequired = extend('checkbox-is-required', {
    validate(val) {

       if(parseInt(val) === 1){
           return true;
       }

        return false;

    },
    message(field){
        return i18n.t('validation_front.required', {_field_: field});
    }
});

// rules="required|date-gt-than:@contract_date,'DD/MM/YYYY'"
// rules="required|date-gt-than:now,'YYYY-MM-DD HH:mm:ss'"
export const dateGreaterThan = extend('date-gt-than', {
    params: ['anotherDate', 'format'],
    validate(val, { anotherDate , format}) {
        if (val === undefined || val === null || anotherDate === undefined || anotherDate === null) {
            return true
        } else {
            // console.log('moment.now()1',moment.now(),anotherDate);
            if(anotherDate == 'now'){
                return  moment(val, format).isAfter(moment().startOf('hour'));
            } else {
                return  moment(val, format).isAfter(moment(anotherDate, format));
            }

        }

    },

    message(field, params){
        let anotherDate = params._anotherDate_;
        if(params.anotherDate == 'now'){
            anotherDate = moment().format(params.format);
        }
        // console.log('fields:', field, params);
        return i18n.t('validation_front.date-gt-than', {_anotherDate_:anotherDate});
    }
});

export const isCheckboxChecked = {
  getMessage() {
    return 'Required one default'; 
  }, 
  validate(_, args) {
    return +args[0] === 1 ? true : 'Required one default'; 
  }
}

//:rules="valid-phone:table,field,current_value"
// export const validPhone = extend('valid-phone', {
//     params: ['validationResultInputVal'],
//     validate: function(val, {validationResultInputVal}){
//         console.log('validat',val,validationResultInputVal);
//         return validationResultInputVal == 1;
//     },
//     message: (field, params) => {
//         return i18n.t('app.invalid_phone_number');
//     }
// });

// export const validPhone = extend('valid-phone', {
//     // params: ['validationResultInputVal'],
//     validate: function(val, d){
//         console.log('validat',val,d);
//         return val;
//     },
//     message: (field, params) => {
//         return i18n.t('app.invalid_phone_number');
//     }
// });
// :rules="is-db-unique:table,field,current_value"
export const isDbUniqueSlug = extend('is-db-unique', {

    params: ['table','field','current_value'],

    validate: (value, { table, field, current_value }) => {
        return new Promise((resolve) => {
            $http.async(
                null,
                'post',
                'api/validation/validate-uniqueness',
                {
                    table: table,
                    field: field,
                    value: value,
                    current_value: current_value
                },
                function (resp) {
                    resolve({
                        valid: resp.data.valid == 1,
                        data: {
                            message: resp.data.message
                        }
                    });
                }
            );
        });
    },
    message: (field, params) => {
        return i18n.t('app.value_is_not_unique');
    }
});