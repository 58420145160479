import Vue from 'vue'
import {
  ValidationProvider, ValidationObserver,/* configure, localize,*/ extend
} from 'vee-validate'


import {
  required,
} from 'vee-validate/dist/rules'
import {
  numeric,
  isCheckboxChecked,
} from './rules/validations'
import {
  email,
} from 'vee-validate/dist/rules'


extend('required', required);
extend('email', email);
extend('isCheckboxChecked', isCheckboxChecked)
// extend('numeric', numeric);


Vue.component([ValidationProvider, ValidationObserver])
