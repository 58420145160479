
import {useCssVar} from "@vueuse/core";

export default {
    namespaced: true,
    state: {
        themeClasses: ['primary', 'secondary', 'success', 'info', 'warning', 'danger', 'light', 'dark'],
        breakpointsClasses: ['xs', 'sm', 'md', 'show-mobile-menu', 'lg', 'xl'],
        themeColors: {},
        themeBreakpoints: {},
        themeColor: ''
    },

    getters: {},

    mutations: {
        FILL_THEME_COLORS_AND_BREAKPOINTS(state, val) {

            state.themeColor = useCssVar(`--theme-color`);
            for (let i = 0, len = state.themeClasses.length; i < len; i++) {
                state.themeColors[state.themeClasses[i]] = useCssVar(`--${state.themeClasses[i]}`, document.documentElement).value.trim()
            }

            for (let i = 0, len = state.breakpointsClasses.length; i < len; i++) { //getting vars from bootstrap.scss :root{--breakpoint-md: 768px;}
                state.themeBreakpoints[state.breakpointsClasses[i]] = Number(useCssVar(`--breakpoint-${state.breakpointsClasses[i]}`, document.documentElement).value.slice(0, -2))
            }
        },

    },
    actions: {

    },
}
