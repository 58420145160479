String.prototype.ucFirst = function () {
    return this.charAt(0).toUpperCase() + this.slice(1);
}
String.prototype.hashCode = function () {
    var hash = 0, i, chr;
    if (this.length === 0) return hash;
    for (i = 0; i < this.length; i++) {
        chr = this.charCodeAt(i);
        hash = ((hash << 5) - hash) + chr;
        hash |= 0;
    }
    return hash;
};
String.prototype.includes = function (substr) {
    return this.indexOf(substr) == -1 ? false : true;
}
String.prototype.br2nl = function(replaceMode) {
    if(!this) return;
    let replaceStr = (replaceMode) ? '' : "\n";
    return this.replace(/<\s*\/?br\s*[\/]?>/gi, replaceStr);
}
String.prototype.nl2br = function() {
    if(!this) return;
    return this.replace(new RegExp('\r?\n','g'), "<br/>");
}
String.prototype.slugify = function($delimiter = '_') {

    let str = this.trim();
// console.log('str trim', str);
    str = str.replace(/\s+/g, $delimiter);
    // console.log('str repl 1', str);
    str=str.toLowerCase().convertString();
    // console.log('str conv', str);
    let delimeterReg = new RegExp("[^a-z\\s\\d"+$delimiter+"]", "g");

    str = str.replace(delimeterReg,'');

    // str = str.replace(/[^a-z0-9 -]/g, '') // remove any non-alphanumeric characters
    //     .replace(/\s+/g, $delimiter);
    return str;
}

String.prototype.convertString = function(toLowerCase){
    let str = this;

    if(toLowerCase){
        str = str.toLowerCase();
    }

        var charMap = {
            'ö': 'o',
            'ç': 'c',
            'ş': 's',
            'ı': 'i',
            'ğ': 'g',
            'ü': 'u',
            'ą': 'a',
            'ć': 'c',
            'ę': 'e',
            'ł': 'l',
            'ń': 'n',
            'ó': 'o',
            'ś': 's',
            'ź': 'z',
            'ż': 'z',
            'а': 'a',
            'б': 'b',
            'в': 'v',
            'г': 'g',
            'д': 'd',
            'е': 'e',
            'ё': 'e',
            'ж': 'j',
            'з': 'z',
            'и': 'i',
            'й': 'i',
            'к': 'k',
            'л': 'l',
            'м': 'm',
            'н': 'n',
            'о': 'o',
            'п': 'p',
            'р': 'r',
            'с': 'c',
            'т': 't',
            'у': 'u',
            'ф': 'f',
            'х': 'h',
            'ц': 'c',
            'ч': 'ch',
            'ш': 'sh',
            'щ': 'sh',
            'Ъ': '',
            'Ы': 'Y',
            'ы': 'y',
            'Ь': '',
            'э': 'a',
            'ю': 'yu',
            'я': 'ia',
        };
        var rx = /(ö|ç|ş|ı|ğ|ü|ą|ć|ę|ł|ń|ó|ś|ź|ż|а|б|в|г|д|е|ё|ж|з|и|й|к|л|м|н|о|п|р|с|т|у|ф|х|ц|ч|ш|щ|ъ|Ы|ы|ь|э|ю|я)/g;

        // if any non-english charr exists,replace it with proper char
        // if (rx.test(str)) {
            str = str.replace(rx, function(m, key, index) {
                return charMap[key];
            });
        // }
        return str;
}

String.prototype.trim = function() {
    return this.replace(/^\s+|\s+$/g, '');
}
String.prototype.containsHtmlTags = function() {
    return  /(<([^>]+)>)/gi.test(this) ;
}

Number.prototype.formatPrice = function (withoutZero) {
    return String(this).formatPrice(withoutZero);
}
Number.prototype.counterFormat = function () {
    let remains = this;
    let str = '';
    let secInDay = 24 * 60 * 60;
    let secInHour = 60 * 60;

    let days = Math.floor(remains / secInDay);

    if (days > 0) {

        remains = remains - days * secInDay;
        days = days.toString();
        str += days + 'd ';
    }
    let hours = Math.floor(remains / secInHour);

    remains = remains - hours * secInHour;
    hours = hours.toString().padStart(2, '0');
    str += hours + ':';

    let minutes = Math.floor(remains / 60);

    remains = remains - minutes * 60;
    minutes = minutes.toString().padStart(2, '0');
    str += minutes + ':';

    remains = Math.ceil(remains);

    if (typeof remains !== 'number') remains = 0;
    remains = remains.toString().padStart(2, '0');
    str += remains;

    return str;

};
String.prototype.formatPrice = function (withoutZero) {
    let maxFractionDigits = 2;
    let number = this;
    let sign = number.charAt(0);

    if(sign != '-' && sign != '+'){
        sign = '';
    }
    if (number !== undefined && number !== '') {
        number = number.replace(/[^0-9.,]/gi, '').replace(/\,/gi, '.');
        let parts = number.split('.');

        if (parts[1] !== undefined) { //several dots bug
            if(parts[1].length > maxFractionDigits ){
                parts[1] = parts[1].substr(0,maxFractionDigits);
            }
            number = parts[0] + '.' + parts[1];
        }

        let options = {};
        if (withoutZero) {
            options.maximumFractionDigits = maxFractionDigits;
            options.minimumFractionDigits = (parts[1])? parts[1].length : 0;
        } else {
            options.minimumFractionDigits = 2;
            options.maximumFractionDigits = maxFractionDigits;
        }

        if (number.slice(-1) != '.') {
            number = new Intl.NumberFormat('ru-RU', options).format(number);
        } else {
            number = new Intl.NumberFormat('ru-RU', options).format(number.slice(0, -1)) + ',';
        }
    }

    return sign + number;
}
String.prototype.isNumeric = function(){ return !isNaN(parseFloat(this)) && !isNaN(this - 0) };

String.prototype.formatPriceToNumber = function () {

    let number = this;
    if (number !== undefined && number !== '') {
        number = number.replace(/\,/gi, '.').replace(/\s/gi, '');
    }
    return number;
}
Array.prototype.pluck = function (key) {
    let slugs = [];
    if(key) {
        slugs = key.split('.');
        // console.log('slugs',slugs);
    }
    return this.map(function (object) {
        let res = object;
        for(let i = 0; i<slugs.length; i++) {
            res = res[slugs[i]];
            // console.log('res',slugs[i],res);
        }
        return res;
    });
}
Array.prototype.sum = function (key) {
    let sum = 0;
     this.forEach(function (object) {
        sum+= object[key];
    });
    return sum;
}
Array.prototype.deleteVal = function (val) {
    let ind = this.indexOf(val);
    if (ind !== -1) {
        this.splice(ind, 1);
        return this;
    }
    return this;
}
Array.prototype.deleteByVal = function (key, val) {
    return this.filter(function (element) {
        return element[key] != val;
    });
}
Array.prototype.findFirstWhere = function (key, val) {

    for(let i=0;i<this.length; i++) {
        if (this[i][key] == val) {
            return this[i];

        }
    }
    return false;
}
Array.prototype.findAll = function (key, val) {
    let items = this.filter(function (element) {
        return element[key] == val;
    });
    return items;
}
Array.prototype.unique = function (key) {
    let obj = {};
    let res = [];
    if (key) {
        for (let i = 0; i < this.length; i++) {
            if (!this[i][key]) continue;
            obj[this[i][key]] = this[i];
        }
    }
    for (let prop in obj) {
        res.push(obj[prop]);
    }
    return res;
}
window['addNewPropsIfNotExists'] = function(collection, prop){
    // console.log(addCollectionProps, this);
    if(isObject(prop)){
        // console.log('here');
        for(let i =0; i<collection.length; i++){
            for(let objProp in prop){
                if(!collection[i].hasOwnProperty(prop)){
                    collection[i][objProp] = prop[objProp];
                }
            }
        }
    }
    return collection;
};
window.in_array = function (needle, haystack) {
    let length = haystack.length;
    for (let i = 0; i < length; i++) {
        if (haystack[i] === needle) return true;
    }
    return false;
}
Object.defineProperty(Object.prototype, 'objectToQueryString', {

    value: function (withoutEpmty) {

        let qString = '';

        let keys = Object.keys(this);
        let length = keys.length;
        let is_first = true;

        for (let i = 0; i < length; i++) {
            let val = this[keys[i]];
            if (!val) val = '';
            if (typeof val != 'array' && typeof  val != 'object') {
                if(!withoutEpmty || (withoutEpmty  && this[keys[i]])){
                     qString += (!is_first? '&' : '') + keys[i] + '=' + this[keys[i]];
                    is_first = false;
                }

            } else {
                for (let j = 0; j < val.length; j++) {
                    if(!withoutEpmty || (withoutEpmty  && val[j]))
                    qString += keys[i] + '[]=' + val[j] + ((i === (length - 1) && j == (val.length - 1)) ? '' : '&');
                }
            }
        }

        return qString? '?'+ qString : '';

    },
    enumerable: false,

});

// !!! also need add them to global mixins else will be undefined;
import moment from "moment";

window['formatDate'] = function (val, toFormat, fromFormat,timezone) {
console.log('format', val, toFormat, fromFormat,timezone);
    if (!val) return '';

    if (fromFormat === 'unix') {
        return moment.unix(val).format(toFormat ? toFormat : 'DD-MM-YYYY hh:mm:ss');
    }

    if (typeof val === 'object' && !fromFormat) {
        console.log('object here');
        return moment(val).format(toFormat ? toFormat : 'DD-MM-YYYY hh:mm:ss');
    }

    if(timezone == 'utc'){
        return moment.utc(moment(val, fromFormat)).format(toFormat ? toFormat : 'DD-MM-YYYY hh:mm:ss');
    } else {
        return moment(moment.utc(val, fromFormat)).local().format(toFormat ? toFormat : 'DD-MM-YYYY hh:mm:ss');
    }

};
window['formatSeconds'] = function (val, toFormat) {

    if (!val) return '';

    return moment().startOf('day')
        .seconds(val)
        .format(toFormat ? toFormat : 'H:mm:ss');
};
window['formatNlToBr'] = function (str, is_xhtml) {
    if (typeof str === 'undefined' || str === null) {
        return '';
    }
    let breakTag = (is_xhtml || typeof is_xhtml === 'undefined') ? '<br />' : '<br>';
    return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2');
};

String.prototype.sprintf = function () {
    let str = this;
    let args = Array.prototype.slice.call(arguments, 0);
    let i = 0;
    return str.replace(/%(s|d|0\d+d|%)/g, function (x, type) {
        var value = args[i++];

        switch (type) {
            case 's':
                return value;
            case 'd':
                return parseInt(value, 10);
            case '%':
                return '%';
            default:
                value = String(parseInt(value, 10));
                var n = Number(type.slice(1, -1));
                return '0'.repeat(n).slice(value.length) + value;
        }
    });
};

window['htmlEncode'] = function (s)
{
    let el = document.createElement("div");
    el.innerText = el.textContent = s;
    s = el.innerHTML;
    el.remove();
    return s;
};

window['isObject'] = obj => typeof obj === 'object' && obj !== null;

window['escapeRegExp'] = function(text) {
    return text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
};

window['getAppInterface'] = function(url) {
    let APP_INTERFACE = 'GUEST';
    let path = url? url : location.pathname;
    if (/\/admin[^\w-](\/)?/i.test(path)) {
        APP_INTERFACE = 'ADMIN'
    } else if (/\/patient[^\w-](\/)?/i.test(path)) {
        APP_INTERFACE = 'PATIENT'
    } else if (/\/doctor[^\w-](\/)?/i.test(path)) {
        APP_INTERFACE = 'DOCTOR'
    }
    return APP_INTERFACE;
};

