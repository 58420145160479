import Vue from 'vue'
import VueRouter from "vue-router";
import RouterRule from './routes';
import {t} from '@resources/js/plugins/i18n'
import store from '@resources/js/store'

Vue.use(VueRouter); // creates $router prop

const router = new VueRouter(RouterRule);

router.beforeEach((to, from, next) => {

    Vue.prototype.$Progress.start();
    let fromRoute = Vue.prototype.$user.CURRENT_ROUTER ? Object.assign({}, Vue.prototype.$user.CURRENT_ROUTER) : false;
    Vue.prototype.$user.PREVIOUS_ROUTER = fromRoute;
    Vue.prototype.$user.CURRENT_ROUTER = to;

    let curLocation = window.location.pathname;
    let plainLinkTo = router.resolve(to);

    let APP_INTERFACE = window.getAppInterface(plainLinkTo.href);

    Vue.prototype.$user.APP_INTERFACE = APP_INTERFACE;

    if (to.query.flashmessage == 1) {

        store.dispatch('app/GET_FLASHMESSAGE', {});

        if (to.query.redirect) {

            if (to.query.redirect == 'time-orders') {
                delete to.query.redirect;
                router.replace({name: 'patient.information', query: {tab: to.query.tab}});
                window.$_app .$Progress.finish();
                return;
            }

        } else {
            delete to.query.flashmessage;
            Vue.prototype.$Progress.finish();
            router.replace(to);
        }

    } else if (typeof to.meta.click_handler === 'function') {
        to.meta.click_handler(window.$_app, t);
        store.commit('verticalMenu/TOGGLE_VERTICAL_MENU_COLLAPSED', true);
        Vue.prototype.$Progress.finish();
        return;
    }

    let userData = Vue.prototype.$user;

    if (userData.auth) {
        if (APP_INTERFACE !== 'GUEST'){
            if(!userData.email_verified_at){
                router.push({name: 'auth.not_verified'});
            }
            if(userData.google2fa_enable == 1 && !userData.dbl_fa_confirmed){
                if (curLocation != router.resolve({name:'auth.confirm_2fa'}).href) {
                    Vue.prototype.$Progress.finish();
                    router.push({name: 'auth.confirm_2fa'});
                } else {
                    window.$_app .$Progress.finish();
                    return;
                }
            }
        }

        let dashboardRoute = {name: Vue.prototype.$user.account_owner_type + '.dashboard'};

        if ( (to.meta.middleware == 'not_for_logged_in')
            || (to.meta.middleware == 'not_for_verified' && userData.email_verified_at)
            || (to.meta.middleware == 'not_for_2fa_confirmed' && (userData.google2fa_enable !=1 || userData.dbl_fa_confirmed)) ){

            Vue.prototype.$Progress.finish();
            router.replace(fromRoute ? fromRoute : dashboardRoute);
            return;
        }

        // if(!userData.email_verified_at && curLocation == router.resolve({name:'auth.not_verified'}).href){
        //     Vue.prototype.$Progress.finish();
        //     return;
        // }

        if (APP_INTERFACE !== 'GUEST'){
            // if(userData.google2fa_enable && !userData.dbl_fa_confirmed) {
            //
            //     if (curLocation != router.resolve({name:'auth.confirm_2fa'}).href) {
            //         Vue.prototype.$Progress.finish();
            //         router.push({name: 'auth.confirm_2fa'});
            //     } else {
            //         window.$_app .$Progress.finish();
            //         return;
            //     }
            // }
            if (APP_INTERFACE.toLowerCase() !== userData.account_owner_type.toLowerCase()) {
                Vue.prototype.$user.APP_INTERFACE = APP_INTERFACE = userData.account_owner_type.toUpperCase();
                if (curLocation != router.resolve({name: APP_INTERFACE.toLowerCase() + '.errors.403'}).href){
                    Vue.prototype.$Progress.finish();
                    router.push({name: APP_INTERFACE.toLowerCase() + '.errors.403'});
                }
            }

        } else {
            next();
        }

    }

    if(APP_INTERFACE !== 'GUEST'){
        Vue.prototype.$user.checkAuth().then(() => { // maybe get from cache first

            if (APP_INTERFACE == 'ADMIN' && to.name != 'admin.errors.403' && to.meta?.permissions && !Vue.prototype.$user.canAll(to.meta.permissions)) {
                Vue.prototype.$Progress.finish();
                router.push({name: 'admin.errors.403'});
            } else {
                next();
            }

        }).catch((e) => {

            console.log('router error', e);
            if (curLocation != router.resolve({name: 'guest.login'}).href){
                Vue.prototype.$Progress.finish();
                router.push({name: 'guest.login'});
            }

        });
    } else {
        next();
    }

});

router.afterEach((to, from, failure) => {

    let title = to.meta.title?  t(to.meta.title) : '';
    document.title = title || localStorage.getItem('app_name');

    Vue.nextTick(() => {
        window.$_app .$Progress.finish();
        window.$_app.$preloader.hide();
        window.$_app.$store.commit('verticalMenu/TOGGLE_VERTICAL_MENU_COLLAPSED', true);
    });

});

export default router
