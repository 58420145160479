export default {

  namespaced: true,

  state: {
    isVerticalMenuCollapsed: true,
    isMenuClosing: false,
  },

  getters: {},

  mutations: {

    TOGGLE_VERTICAL_MENU_COLLAPSED(state, val) {
      state.isVerticalMenuCollapsed = val !== undefined ? val : !state.isVerticalMenuCollapsed
    },

  },

  actions: {},
}
