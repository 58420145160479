export default {
  namespaced: true,
  state: {

    shopping_cart_items: [],
    doctor_reserving_data:[],
    wished_items_count: 0
  },

  getters: {

  },

  mutations: {

    setShopCartItems(state, data){
      // alert(data.length);
      state.shopping_cart_items = data.shopping_cart_items;
    },
    updateShopItemQuantuty(state, data){
      state.shopping_cart_items.findFirstWhere('id',data.item_id)['selected_quantity'] = data.quantity;
    },
    updateDoctorReservingData(state, data){
      state.doctor_reserving_data = data;
    }

  },
  actions: {

    GET_SHOPPING_CART({dispatch, state, commit, rootState}, data) {

      return new Promise((resolve, reject) => {
        if(!data){
          data = {};
        }

        return $http.publicAsync('get', 'api/shopping-cart',data, function(response){
          commit('setShopCartItems', response.data);
        }, function(error){
          reject(error);
        });

      });

    },


    RESERVE_DOCTOR_TIME({dispatch, state, commit, rootState}, {data, vm}) {

      return new Promise((resolve, reject) => {

        return $http.async(vm,'post', `api/patient/doctors/${data.doctor_id}/reserve-time`, data, function(resp){
          // commit('setShopCartItems', response.data);
          resolve(resp);
        }, function(error){
          reject(error);
        });

      });

    },


  },
}
