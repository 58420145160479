import {assignLoggedInRoutes} from "@resources/js/routes/loggedUser-routes";
import user from "@resources/js/plugins/custom/user/user";

export default {
    path: '/doctor',
    component: () => import('@views/layouts/doctorLayout'),
    // name: 'doctor',
    meta: {
        breadcrumb: ''
    },
    children: [
        {
            path: '',
            component: () => import('@views/doctor/DashboardDoctor'),
            name: 'doctor.dashboard',
            meta: {
                breadcrumb: '',
                title: 'app.dashboard'
            },
            redirect: to => {//todo temporary
                // console.log('redirect');
                return {name: 'doctor.information'}
            },
        },
     
        {
            path: 'information',
            component: () => import('@views/doctor/PageDoctorInformation'),
            name: 'doctor.information',
            meta: {
                breadcrumb: 'app.information',
                title: 'app.information',
                page_content_class: 'content-container container p-0',
                page_id: 'doctor-information-page'
            }
        },
        {
            path: 'firm_products',
            component: () => import('@views/admin/products/list'),
            name: 'doctor.firm_products',
            meta: {
                breadcrumb: 'app.firm-products',
                title: 'app.firm-products'
            }
        },
        {
            path: 'schedules',
            name: 'doctor.schedules',
            meta: {
                breadcrumb: 'app.schedules',
                title: 'app.schedules',
                click_handler: function(vm, t){
                    vm.$root.$bvModal.show('doctor_schedules-modal');
                }
            }
        },

        {
            path: 'patients',
            component: () => import('@views/doctor/patients/list'),
            name: 'doctor.patients',
            meta: {
                breadcrumb: 'app.patients',
                title: 'app.patients'
            }
        },
        {
            path: 'prescriptions',
            component: () => import('@views/logged/patient-prescriptions/list'),
            name: 'doctor.patient_prescriptions.list',
            meta: {
                breadcrumb: 'app.prescriptions',
                title:'app.prescriptions',
                // page_content_class: 'content-container container',
                page_id: 'doctor-prescr-page'
            }
        },
        // {
        //     path: 'add-product-modal',
        //     // component: '',
        //     name: 'doctor.add_product_modal',
        //     meta: {
        //         breadcrumb: 'app.add_product',
        //         title: 'app.add_product',
        //         click_action: 'add_product_modal',
        //         click_handler: function(vm){
        //             vm.$root.$bvModal.show('doctor-modal');
        //         }
        //     }
        // },

        {
            path: 'orders',
            component: () => import('@views/doctor/orders/list'),
            name: 'doctor.orders',
            meta: {
                breadcrumb: 'app.orders',
                title: 'app.orders',
                page_id: 'doctor-orders-page'
            }
        },

        ...(assignLoggedInRoutes('doctor')),


    ]
}