// import IntlMessageFormat from 'intl-messageformat'

// import  { Formatter } from 'vue-i18n'
//
// import VueI18n from 'vue-i18n'
export class CustomFormatter {
    // i18;
    constructor () {
        // this.i18 = i18;
    }

    //
    // interpolate
    //
    // @param {string} message
    //   string of list or named format.
    //   e.g.
    //   - named formatting: 'Hi {name}'
    //   - list formatting: 'Hi {0}'
    //
    // @param {Object | Array} values
    //   values of `message` interpolation.
    //   passed values with `$t`, `$tc` and `i18n` functional component.
    //   e.g.
    //   - $t('hello', { name: 'kazupon' }) -> passed values: Object `{ name: 'kazupon' }`
    //   - $t('hello', ['kazupon']) -> passed values: Array `['kazupon']`
    //   - `i18n` functional component (component interpolation)
    //     <i18n path="hello">
    //       <p>kazupon</p>
    //       <p>how are you?</p>
    //     </i18n>
    //     -> passed values: Array (included VNode):
    //        `[VNode{ tag: 'p', text: 'kazupon', ...}, VNode{ tag: 'p', text: 'how are you?', ...}]`
    //
    // @return {Array<any>}
    //   interpolated values. you need to return the following:
    //   - array of string, when is using `$t` or `$tc`.
    //   - array included VNode object, when is using `i18n` functional component.
    //
    interpolate (message, values) {
//todo write pluration processing
        if(values){
            // console.log('message, values',message, values, typeof values.count);
            if(typeof values == 'object'){
                for(let prop in values){
                    message = message.replace(':'+prop,values[prop]);
                }
            }  else {
                // console.log('this.i18 form',VueI18n.Formatter);
                // return Formatter(message, values);
            }
        }

        return [message]
    }
}