import Vue from 'vue'
import VueI18n from 'vue-i18n'
// import {createI18n} from 'vue-i18n-bridge'
import { CustomFormatter } from './compilation'

Vue.use(VueI18n, /*{ bridge: true }*/);

// alert(2);
let locale = document.documentElement.lang.substr(0, 2);
let messages  = require('./locales/en.json');
let i18n = new VueI18n({
  locale: locale,
  formatter: new CustomFormatter(),
  fallbackLocale: 'en',
  messages: {[locale]:messages},
});


export function t(alias){
  return i18n.t(alias);
}

export default i18n
