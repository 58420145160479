import Vue from 'vue'
import {DatePicker,Loading} from 'element-ui'
// import {DatePicker, Loading, Step, Steps} from "element-ui";
// import 'element-ui/lib/theme-chalk/index.css';
// import 'element-ui/lib/theme-chalk/steps.css';
// import 'element-ui/lib/theme-chalk/step.css';
import 'element-ui/lib/theme-chalk/date-picker.css';
// import 'element-ui/lib/theme-chalk/icon.css';

// Vue.component(Steps.name, Steps)
// Vue.component(Step.name, Step)
Vue.component(DatePicker.name, DatePicker)
Vue.use(Loading.directive);

