import adminRoutes from "../../routes/admin-routes";
import guestRoutes from "../../routes/guest-routes";
import patientRoutes from "../../routes/patient-routes";
import doctorRoutes from "../../routes/doctor-routes";

//0) in every childs we can use <router-view></router-view>? template inherits from parent
//1) if router.meta.access == 'not_for_logged_in' => then redirect to dashboard
//2) page_id: 'chat-page',
//3) page_content_class: 'content-container container'

const rule = {
    scrollBehavior(to, from, savedPosition) {
      // always scroll to top
      return { top: 0 }
    },
    mode: 'history',
    routes: [

        guestRoutes,

        patientRoutes,

        doctorRoutes,

        adminRoutes,

        // {
        //     path: '/admin/*',
        //     component: () => import('@views/layouts/adminLayout'),
        //
        //     children:[
        //         {
        //             path: '',
        //             component: () => import('@views/includes/errors/404'),
        //             name: 'admin.errors.404',
        //             meta: {
        //                 breadcrumb: 'app.404',
        //                 title: 'app.404',
        //             },
        //
        //         },
        //     ]
        // },
        // {
        //     path: '/doctor/*',
        //     component: () => import('@views/layouts/doctorLayout'),
        //
        //     children:[
        //         {
        //             path: '',
        //             component: () => import('@views/includes/errors/404'),
        //             name: 'doctor.errors.404',
        //             meta: {
        //                 breadcrumb: 'app.404',
        //                 title: 'app.404',
        //             },
        //
        //         },
        //     ]
        // },
        // {
        //     path: '/patient/*',
        //     component: () => import('@views/layouts/patientLayout'),
        //
        //     children:[
        //         {
        //             path: '',
        //             component: () => import('@views/includes/errors/404'),
        //             name: 'patient.errors.404',
        //             meta: {
        //                 breadcrumb: 'app.404',
        //                 title: 'app.404',
        //             },
        //
        //         },
        //     ]
        // },
        //
        {
            // path: '/:pathMatch(.*)*',
            path: '*',
            component: () => import('@views/layouts/guestLayout'),

            children:[
                {
                    path: '',
                    component: () => import('@views/includes/errors/404'),
                    name: 'errors.404',
                    meta: {
                        breadcrumb: 'app.404',
                        title: 'app.404',
                    },

                },
            ]
        },
    ]

};

export default rule;
